// type 组件类型
// icon 组件图标
// isSearch 是否为查询条件
// isLikeSearch 是否模糊查询
// isInTable 列表是否显示
// position H5端显示的位置
// width 输入框宽度
// defaultValue 默认值
// required 是否必填
// dataType 输入的数据类型
// pattern 校验的正则表达式
// placeholder 占位文本
// disabled 是否禁用
// inline 是否在一行显示
// showLabel 是否显示label
// multiple 是否多选
// options 选项
// remote 是否后端数据
// remoteOptions 后端数据选项
// remoteFunc 获取后端数据的方法
// filterable 选择框是否可搜索
// clearable 是否可清除
// checkStrictly 是否可选任一级
// special 特殊字段标识
export const basicComponents = [
  {
    type: 'input',
    icon: 'icon-input',
    isSearch: false,
    isLikeSearch: true,
    isInTable: false,
    position: '',
    options: {
      width: '100%',
      defaultValue: '',
      required: false,
      dataType: 'string',
      pattern: '',
      placeholder: '',
      disabled: false
    }
  },
  {
    type: 'textarea',
    icon: 'icon-diy-com-textarea',
    isInTable: false,
    position: '',
    options: {
      width: '100%',
      defaultValue: '',
      required: false,
      disabled: false,
      pattern: '',
      placeholder: ''
    }
  },
  {
    type: 'number',
    icon: 'icon-number',
    isInTable: false,
    position: '',
    options: {
      width: '',
      required: false,
      defaultValue: 0,
      min: '',
      max: '',
      step: 1,
      disabled: false,
      controlsPosition: ''
    }
  },
  {
    type: 'radio',
    icon: 'icon-radio-active',
    isInTable: false,
    options: {
      inline: false,
      defaultValue: '',
      showLabel: false,
      options: [
        {
          value: 'Option 1',
          label: 'Option 1'
        },
        {
          value: 'Option 2',
          label: 'Option 2'
        },
        {
          value: 'Option 3',
          label: 'Option 3'
        }
      ],
      required: false,
      width: '',
      remote: false,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      remoteFunc: '',
      disabled: false
    }
  },
  {
    type: 'checkbox',
    icon: 'icon-check-box',
    isInTable: false,
    options: {
      inline: false,
      defaultValue: [],
      showLabel: false,
      options: [
        {
          value: 'Option 1'
        },
        {
          value: 'Option 2'
        },
        {
          value: 'Option 3'
        }
      ],
      required: false,
      width: '',
      remote: false,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      remoteFunc: '',
      disabled: false
    }
  },
  {
    type: 'time',
    icon: 'icon-time',
    isInTable: false,
    position: '',
    options: {
      defaultValue: '21:19:56',
      readonly: false,
      disabled: false,
      editable: true,
      clearable: true,
      placeholder: '',
      startPlaceholder: '',
      endPlaceholder: '',
      isRange: false,
      arrowControl: true,
      format: 'HH:mm:ss',
      required: false,
      width: ''
    }
  },
  {
    type: 'date',
    icon: 'icon-date',
    isSearch: false,
    isInTable: false,
    position: '',
    options: {
      defaultValue: '',
      readonly: false,
      disabled: false,
      editable: true,
      clearable: true,
      placeholder: '',
      startPlaceholder: '',
      endPlaceholder: '',
      type: 'date',
      format: 'yyyy-MM-dd',
      timestamp: false,
      required: false,
      width: ''
    }
  },
  {
    type: 'rate',
    icon: 'icon-pingfen1',
    isInTable: false,
    position: '',
    options: {
      defaultValue: null,
      max: 5,
      disabled: false,
      allowHalf: false,
      required: false
    }
  },
  {
    type: 'color',
    icon: 'icon-color',
    options: {
      defaultValue: '',
      disabled: false,
      showAlpha: false,
      required: false
    }
  },
  {
    type: 'select',
    icon: 'icon-select',
    isSearch: false,
    isInTable: false,
    position: '',
    options: {
      defaultValue: '',
      multiple: false,
      disabled: false,
      clearable: true,
      placeholder: '',
      required: false,
      showLabel: true,
      width: '100%',
      options: [
        {
          value: 'Option 1'
        },
        {
          value: 'Option 2'
        },
        {
          value: 'Option 3'
        }
      ],
      remote: false,
      filterable: false,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      remoteFunc: ''
    }
  },
  {
    type: 'switch',
    icon: 'icon-switch',
    isInTable: false,
    options: {
      defaultValue: false,
      required: false,
      disabled: false
    }
  },
  {
    type: 'slider',
    icon: 'icon-slider',
    options: {
      defaultValue: 0,
      disabled: false,
      required: false,
      min: 0,
      max: 100,
      step: 1,
      showInput: false,
      range: false,
      width: ''
    }
  },
  {
    type: 'text',
    icon: 'icon-wenzishezhi-',
    options: {
      defaultValue: 'This is a text',
      customClass: ''
    }
  }
]

export const advanceComponents = [
  {
    type: 'imgupload',
    icon: 'icon-tupian',
    options: {
      defaultValue: [],
      size: {
        width: 100,
        height: 100
      },
      width: '',
      disabled: false,
      length: 8,
      multiple: false,
      isDelete: false,
      min: 0,
      isEdit: false,
      action: '/file/file/upload',
      path: 'files/cumtomize/pageTable'
    }
  },
  {
    type: 'imgH5upload',
    icon: 'icon-tupian',
    isInTable: true,
    options: {
      defaultValue: [],
      size: {
        width: 100,
        height: 100
      },
      width: '',
      disabled: false,
      length: 8,
      multiple: false,
      isDelete: false,
      min: 0,
      isEdit: false,
      action: '/wxapp/file/upload',
      path: 'wxapp/files/customize/pageTable'
    }
  },
  {
    type: 'editor',
    icon: 'icon-fuwenbenkuang',
    options: {
      defaultValue: '',
      width: ''
    }
  },
  {
    type: 'cascader',
    icon: 'icon-jilianxuanze',
    isSearch: false,
    isInTable: false,
    position: '',
    options: {
      defaultValue: [],
      width: '100%',
      placeholder: '请选择',
      multiple: false,
      disabled: false,
      clearable: true,
      checkStrictly: false,
      remote: true,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label',
        children: 'children'
      },
      remoteFunc: ''
    }
  }
]

export const layoutComponents = [
  {
    type: 'grid',
    icon: 'icon-grid-',
    columns: [
      {
        span: 12,
        list: []
      },
      {
        span: 12,
        list: []
      }
    ],
    options: {
      gutter: 0,
      justify: 'start',
      align: 'top'
    }
  }
]

// pc端特殊字段
export const specialPCComponents = [
  {
    type: 'res', // 居民字段
    name: '居民',
    icon: 'icon-user',
    options: [{
      type: 'grid',
      icon: 'icon-grid-',
      special: 'res',
      columns: [{
        span: 8,
        list: [{
          type: 'input',
          icon: 'icon-input',
          name: '姓名',
          model: 'name',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 1),
          rules: [{
            message: '姓名格式不正确',
            type: 'string'
          }, {
            message: '姓名必须填写',
            required: true
          }],
          isSearch: false,
          isLikeSearch: true,
          isInTable: true,
          position: '',
          options: {
            width: '100%',
            defaultValue: '',
            required: true,
            dataType: 'string',
            pattern: '',
            placeholder: '请输入',
            disabled: false
          }
        }]
      }, {
        span: 8,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '性别',
          model: 'sex',
          modelStr: 'sexStr',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 2),
          isSearch: false,
          isInTable: false,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: false,
            showLabel: true,
            width: '100%',
            options: [{
              value: 1, label: '男'
            }, {
              value: 2, label: '女'
            }],
            remote: false,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label'
            },
            remoteFunc: ''
          }
        }]
      }, {
        span: 8,
        list: [{
          type: 'input',
          icon: 'icon-input',
          name: '手机号',
          model: 'mobile',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 3),
          rules: [],
          isSearch: false,
          isLikeSearch: true,
          isInTable: true,
          position: '',
          options: {
            width: '100%',
            defaultValue: '',
            required: false,
            dataType: 'string',
            pattern: '',
            placeholder: '请输入',
            disabled: false
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }, {
      type: 'grid',
      icon: 'icon-grid-',
      special: 'res',
      columns: [{
        span: 8,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '证件类型',
          model: 'idNumberType',
          modelStr: 'idNumberTypeStr',
          special: 'res',
          rules: [{
            message: '证件类型必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 4),
          isSearch: false,
          isInTable: false,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: true,
            showLabel: false,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label'
            },
            remoteFunc: 'getIdNumberTypeList'
          }
        }]
      }, {
        span: 8,
        list: [{
          type: 'input',
          icon: 'icon-input',
          name: '证件号码',
          model: 'idNumber',
          special: 'res',
          rules: [{
            message: '证件号码必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 5),
          isSearch: true,
          isLikeSearch: true,
          isInTable: true,
          position: '',
          options: {
            width: '100%',
            defaultValue: '',
            required: true,
            dataType: 'string',
            pattern: '',
            placeholder: '请输入',
            disabled: false
          }
        }]
      }, {
        span: 8,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '国籍',
          model: 'nationality',
          modelStr: 'nationalityStr',
          special: 'res',
          rules: [{
            message: '国籍必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 7),
          isSearch: false,
          isInTable: false,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: true,
            showLabel: false,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label'
            },
            remoteFunc: 'getNationalityList'
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }, {
      type: 'grid',
      icon: 'icon-grid-',
      special: 'res',
      columns: [{
        span: 8,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '民族',
          model: 'nation',
          modelStr: 'nationStr',
          special: 'res',
          rules: [{
            message: '民族必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 6),
          isSearch: false,
          isInTable: false,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: true,
            showLabel: false,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label'
            },
            remoteFunc: 'getNationList'
          }
        }]
      }, {
        span: 8,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '学历',
          model: 'education',
          modelStr: 'educationStr',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 8),
          isSearch: false,
          isInTable: false,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: false,
            showLabel: false,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label'
            },
            remoteFunc: 'getEducationList'
          }
        }]
      }, {
        span: 8,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '婚姻',
          model: 'marriage',
          modelStr: 'marriageStr',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 9),
          isSearch: false,
          isInTable: false,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: false,
            showLabel: false,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label'
            },
            remoteFunc: 'getMarriageList'
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }, {
      type: 'grid',
      icon: 'icon-grid-',
      special: 'res',
      columns: [{
        span: 12,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '户籍性质',
          model: 'registryType',
          modelStr: 'registryTypeStr',
          special: 'res',
          rules: [{
            message: '户籍性质必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 15),
          isSearch: true,
          isInTable: true,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: true,
            showLabel: false,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label'
            },
            remoteFunc: 'getRegistryTypeList'
          }
        }]
      }, {
        span: 12,
        list: [{
          type: 'input',
          icon: 'icon-input',
          name: '户籍地址',
          model: 'regAddress',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 10),
          rules: [],
          isSearch: false,
          isLikeSearch: true,
          isInTable: false,
          position: '',
          options: {
            width: '100%',
            defaultValue: '',
            required: false,
            dataType: 'string',
            pattern: '',
            placeholder: '请输入',
            disabled: false
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }, {
      type: 'grid',
      icon: 'icon-grid-',
      special: 'res',
      columns: [{
        span: 12,
        list: [{
          type: 'input',
          icon: 'icon-input',
          name: '现居住地',
          model: 'nowAddress',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 11),
          rules: [],
          isSearch: false,
          isLikeSearch: true,
          isInTable: false,
          position: '',
          options: {
            width: '100%',
            defaultValue: '',
            required: false,
            dataType: 'string',
            pattern: '',
            placeholder: '请输入',
            disabled: false
          }
        }]
      }, {
        span: 12,
        list: [{
          type: 'input',
          icon: 'icon-input',
          name: '工作单位',
          model: 'company',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 12),
          rules: [],
          isSearch: false,
          isLikeSearch: true,
          isInTable: false,
          position: '',
          options: {
            width: '100%',
            defaultValue: '',
            required: false,
            dataType: 'string',
            pattern: '',
            placeholder: '请输入',
            disabled: false
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }, {
      type: 'grid',
      icon: 'icon-grid-',
      special: 'res',
      columns: [{
        span: 4,
        list: [{
          type: 'switch',
          icon: 'icon-switch',
          name: '是否死亡',
          model: 'death',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 13),
          isInTable: false,
          options: {
            defaultValue: 0,
            required: false,
            disabled: false,
            activeValue: 1,
            inactiveValue: 0
          }
        }]
      }, {
        span: 8,
        list: [{
          type: 'date',
          icon: 'icon-date',
          name: '死亡日期',
          model: 'deathDate',
          special: 'res',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 14),
          isSearch: false,
          isInTable: false,
          position: '',
          options: {
            defaultValue: '',
            readonly: false,
            disabled: false,
            editable: true,
            clearable: true,
            placeholder: '',
            startPlaceholder: '',
            endPlaceholder: '',
            type: 'date',
            format: 'yyyy-MM-dd',
            timestamp: false,
            required: false,
            width: '100%'
          }
        }]
      }, {
        span: 12,
        list: []
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }]
  }, {
    type: 'house', // 楼房字段
    name: '楼房',
    icon: 'icon-fangchan',
    options: [{
      type: 'grid',
      icon: 'icon-grid-',
      special: 'house',
      columns: [{
        span: 12,
        list: [{
          type: 'cascader',
          icon: 'icon-jilianxuanze',
          name: '组织机构',
          model: 'orgId',
          modelStr: 'orgIdStr',
          special: 'house',
          rules: [{
            message: '组织机构必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 1),
          isSearch: false,
          isInTable: true,
          position: '',
          options: {
            required: true,
            defaultValue: [],
            width: '100%',
            placeholder: '请选择',
            multiple: false,
            checkStrictly: true,
            disabled: false,
            clearable: true,
            remote: true,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label',
              children: 'children'
            },
            remoteFunc: 'getOrgList'
          }
        }]
      }, {
        span: 12,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '楼幢',
          model: 'buildingId',
          modelStr: 'buildingIdStr',
          special: 'house',
          rules: [{
            message: '楼幢必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 2),
          isSearch: false,
          isInTable: true,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: true,
            showLabel: false,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'id',
              label: 'name'
            },
            remoteFunc: 'getBuildingList'
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }, {
      type: 'grid',
      icon: 'icon-grid-',
      special: 'house',
      columns: [{
        span: 12,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '单元',
          model: 'unitId',
          modelStr: 'unitIdStr',
          special: 'house',
          rules: [{
            message: '单元必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 3),
          isSearch: false,
          isInTable: true,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: true,
            showLabel: true,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'id',
              label: 'name'
            },
            remoteFunc: 'getUnitList'
          }
        }]
      }, {
        span: 12,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '房屋',
          model: 'houseId',
          modelStr: 'houseIdStr',
          special: 'house',
          rules: [{
            message: '房屋必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 4),
          isSearch: false,
          isInTable: true,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: true,
            showLabel: true,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'id',
              label: 'name'
            },
            remoteFunc: 'getHouseList'
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }]
  }, {
    type: 'device', // 设备字段
    name: '设备',
    icon: 'icon-shebei',
    options: [{
      type: 'grid',
      icon: 'icon-grid-',
      special: 'device',
      columns: [{
        span: 12,
        list: [{
          type: 'input',
          icon: 'icon-input',
          name: '设备名称',
          model: 'name',
          special: 'device',
          rules: [{
            message: '设备名称必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 1),
          isSearch: false,
          isLikeSearch: true,
          isInTable: true,
          position: '',
          options: {
            width: '100%',
            defaultValue: '',
            required: true,
            dataType: 'string',
            pattern: '',
            placeholder: '请输入',
            disabled: false
          }
        }]
      }, {
        span: 12,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '设备类型',
          model: 'type',
          modelStr: 'typeStr',
          special: 'device',
          rules: [{
            message: '设备类型必须填写',
            required: true
          }],
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 2),
          isSearch: false,
          isInTable: true,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: true,
            showLabel: false,
            width: '100%',
            options: [],
            remote: true,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label'
            },
            remoteFunc: 'getDeviceTypeList'
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }, {
      type: 'grid',
      icon: 'icon-grid-',
      special: 'device',
      columns: [{
        span: 12,
        list: [{
          type: 'select',
          icon: 'icon-select',
          name: '位置',
          model: 'location',
          modelStr: 'locationStr',
          special: 'device',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 3),
          isSearch: false,
          isInTable: false,
          position: '',
          options: {
            defaultValue: '',
            multiple: false,
            disabled: false,
            clearable: true,
            placeholder: '请选择',
            required: false,
            showLabel: true,
            width: '100%',
            options: [{
              value: 1,
              label: '地上'
            }, {
              value: 2,
              label: '地下'
            }],
            remote: false,
            filterable: false,
            remoteOptions: [],
            props: {
              value: 'value',
              label: 'label'
            },
            remoteFunc: ''
          }
        }]
      }, {
        span: 12,
        list: [{
          type: 'date',
          icon: 'icon-date',
          name: '安装日期',
          model: 'locationDate',
          special: 'device',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 4),
          isSearch: false,
          isInTable: false,
          position: '',
          options: {
            defaultValue: '',
            readonly: false,
            disabled: false,
            editable: true,
            clearable: true,
            placeholder: '',
            startPlaceholder: '',
            endPlaceholder: '',
            type: 'date',
            format: 'yyyy-MM-dd',
            timestamp: false,
            required: false,
            width: '100%'
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }, {
      type: 'input',
      icon: 'icon-input',
      name: '详细地点',
      model: 'address',
      special: 'device',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 5),
      rules: [],
      isSearch: false,
      isLikeSearch: true,
      isInTable: false,
      position: '',
      options: {
        width: '100%',
        defaultValue: '',
        required: false,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'grid',
      icon: 'icon-grid-',
      special: 'device',
      columns: [{
        span: 12,
        list: [{
          type: 'input',
          icon: 'icon-input',
          name: '负责人',
          model: 'charger',
          special: 'device',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 6),
          rules: [],
          isSearch: false,
          isLikeSearch: true,
          isInTable: false,
          position: '',
          options: {
            width: '100%',
            defaultValue: '',
            required: false,
            dataType: 'string',
            pattern: '',
            placeholder: '请输入',
            disabled: false
          }
        }]
      }, {
        span: 12,
        list: [{
          type: 'input',
          icon: 'icon-input',
          name: '联系电话',
          model: 'mobile',
          special: 'device',
          key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 7),
          rules: [],
          isSearch: false,
          isLikeSearch: true,
          isInTable: false,
          position: '',
          options: {
            width: '100%',
            defaultValue: '',
            required: false,
            dataType: 'string',
            pattern: '',
            placeholder: '请输入',
            disabled: false
          }
        }]
      }],
      options: {
        gutter: 0,
        justify: 'start',
        align: 'top'
      }
    }]
  }
]

// h5端特殊字段
export const specialH5Components = [
  {
    type: 'res', // 居民字段
    name: '居民',
    icon: 'icon-user',
    options: [{
      type: 'input',
      icon: 'icon-input',
      name: '姓名',
      model: 'name',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 1),
      rules: [{
        message: '姓名必须填写',
        required: true
      }],
      isSearch: false,
      isLikeSearch: true,
      isInTable: true,
      position: 3,
      options: {
        width: '100%',
        defaultValue: '',
        required: true,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '性别',
      model: 'sex',
      modelStr: 'sexStr',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 2),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: false,
        showLabel: true,
        width: '100%',
        options: [{
          value: 1, label: '男'
        }, {
          value: 2, label: '女'
        }],
        remote: false,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label'
        },
        remoteFunc: ''
      }
    }, {
      type: 'input',
      icon: 'icon-input',
      name: '手机号',
      model: 'mobile',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 3),
      rules: [],
      isSearch: false,
      isLikeSearch: true,
      isInTable: false,
      position: '',
      options: {
        width: '100%',
        defaultValue: '',
        required: false,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '证件类型',
      model: 'idNumberType',
      modelStr: 'idNumberTypeStr',
      special: 'res',
      rules: [{
        message: '证件类型必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 4),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: true,
        showLabel: false,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label'
        },
        remoteFunc: 'getIdNumberTypeList'
      }
    }, {
      type: 'input',
      icon: 'icon-input',
      name: '证件号码',
      model: 'idNumber',
      special: 'res',
      rules: [{
        message: '证件号码必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 5),
      isSearch: true,
      isLikeSearch: true,
      isInTable: true,
      position: 4,
      options: {
        width: '100%',
        defaultValue: '',
        required: true,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '国籍',
      model: 'nationality',
      modelStr: 'nationalityStr',
      special: 'res',
      rules: [{
        message: '国籍必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 6),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: true,
        showLabel: false,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label'
        },
        remoteFunc: 'getNationalityList'
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '民族',
      model: 'nation',
      modelStr: 'nationStr',
      special: 'res',
      rules: [{
        message: '民族必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 7),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: true,
        showLabel: false,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label'
        },
        remoteFunc: 'getNationList'
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '学历',
      model: 'education',
      modelStr: 'educationStr',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 8),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: false,
        showLabel: false,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label'
        },
        remoteFunc: 'getEducationList'
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '婚姻',
      model: 'marriage',
      modelStr: 'marriageStr',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 9),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: false,
        showLabel: false,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label'
        },
        remoteFunc: 'getMarriageList'
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '户籍性质',
      model: 'registryType',
      modelStr: 'registryTypeStr',
      special: 'res',
      rules: [{
        message: '户籍性质必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 10),
      isSearch: true,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: true,
        showLabel: false,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label'
        },
        remoteFunc: 'getRegistryTypeList'
      }
    }, {
      type: 'input',
      icon: 'icon-input',
      name: '户籍地址',
      model: 'regAddress',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 11),
      rules: [],
      isSearch: false,
      isLikeSearch: true,
      isInTable: false,
      position: '',
      options: {
        width: '100%',
        defaultValue: '',
        required: false,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'input',
      icon: 'icon-input',
      name: '现居住地',
      model: 'nowAddress',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 12),
      rules: [],
      isSearch: false,
      isLikeSearch: true,
      isInTable: false,
      position: '',
      options: {
        width: '100%',
        defaultValue: '',
        required: false,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'input',
      icon: 'icon-input',
      name: '工作单位',
      model: 'company',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 13),
      rules: [],
      isSearch: false,
      isLikeSearch: true,
      isInTable: false,
      position: '',
      options: {
        width: '100%',
        defaultValue: '',
        required: false,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'switch',
      icon: 'icon-switch',
      name: '是否死亡',
      model: 'death',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 14),
      isInTable: false,
      options: {
        defaultValue: 0,
        required: false,
        disabled: false,
        activeValue: 1,
        inactiveValue: 0
      }
    }, {
      type: 'date',
      icon: 'icon-date',
      name: '死亡日期',
      model: 'deathDate',
      special: 'res',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 15),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        readonly: false,
        disabled: false,
        editable: true,
        clearable: true,
        placeholder: '',
        startPlaceholder: '',
        endPlaceholder: '',
        type: 'date',
        format: 'yyyy-MM-dd',
        timestamp: false,
        required: false,
        width: '100%'
      }
    }]
  }, {
    type: 'house', // 楼房字段
    name: '楼房',
    icon: 'icon-fangchan',
    options: [{
      type: 'cascader',
      icon: 'icon-jilianxuanze',
      name: '组织机构',
      model: 'orgId',
      special: 'house',
      rules: [{
        message: '组织机构必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 1),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        required: true,
        defaultValue: [],
        width: '100%',
        placeholder: '请选择',
        multiple: false,
        disabled: false,
        checkStrictly: true,
        clearable: true,
        remote: true,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label',
          children: 'children'
        },
        remoteFunc: 'getOrgList'
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '楼幢',
      model: 'buildingId',
      modelStr: 'buildingIdStr',
      special: 'house',
      rules: [{
        message: '楼幢必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 2),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: true,
        showLabel: false,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'id',
          label: 'name'
        },
        remoteFunc: 'getBuildingList'
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '单元',
      model: 'unitId',
      modelStr: 'unitIdStr',
      special: 'house',
      rules: [{
        message: '单元必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 3),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: true,
        showLabel: true,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'id',
          label: 'name'
        },
        remoteFunc: 'getUnitList'
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '房屋',
      model: 'houseId',
      modelStr: 'houseIdStr',
      special: 'house',
      rules: [{
        message: '房屋必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 4),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: true,
        showLabel: true,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'id',
          label: 'name'
        },
        remoteFunc: 'getHouseList'
      }
    }]
  }, {
    type: 'device', // 设备字段
    name: '设备',
    icon: 'icon-shebei',
    options: [{
      type: 'input',
      icon: 'icon-input',
      name: '设备名称',
      model: 'name',
      special: 'device',
      rules: [{
        message: '设备名称必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 1),
      isSearch: false,
      isLikeSearch: true,
      isInTable: true,
      position: 1,
      options: {
        width: '100%',
        defaultValue: '',
        required: true,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '设备类型',
      model: 'type',
      modelStr: 'typeStr',
      special: 'device',
      rules: [{
        message: '设备类型必须填写',
        required: true
      }],
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 2),
      isSearch: false,
      isInTable: true,
      position: 2,
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: true,
        showLabel: false,
        width: '100%',
        options: [],
        remote: true,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label'
        },
        remoteFunc: 'getDeviceTypeList'
      }
    }, {
      type: 'select',
      icon: 'icon-select',
      name: '位置',
      model: 'location',
      modelStr: 'locationStr',
      special: 'device',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 3),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        multiple: false,
        disabled: false,
        clearable: true,
        placeholder: '请选择',
        required: false,
        showLabel: true,
        width: '100%',
        options: [{
          value: 1,
          label: '地上'
        }, {
          value: 2,
          label: '地下'
        }],
        remote: false,
        filterable: false,
        remoteOptions: [],
        props: {
          value: 'value',
          label: 'label'
        },
        remoteFunc: ''
      }
    }, {
      type: 'date',
      icon: 'icon-date',
      name: '安装日期',
      model: 'locationDate',
      special: 'device',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 4),
      isSearch: false,
      isInTable: false,
      position: '',
      options: {
        defaultValue: '',
        readonly: false,
        disabled: false,
        editable: true,
        clearable: true,
        placeholder: '',
        startPlaceholder: '',
        endPlaceholder: '',
        type: 'date',
        format: 'yyyy-MM-dd',
        timestamp: false,
        required: false,
        width: '100%'
      }
    }, {
      type: 'input',
      icon: 'icon-input',
      name: '详细地点',
      model: 'address',
      special: 'device',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 5),
      rules: [],
      isSearch: false,
      isLikeSearch: true,
      isInTable: false,
      position: '',
      options: {
        width: '100%',
        defaultValue: '',
        required: false,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'input',
      icon: 'icon-input',
      name: '负责人',
      model: 'charger',
      special: 'device',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 6),
      rules: [],
      isSearch: false,
      isLikeSearch: true,
      isInTable: false,
      position: '',
      options: {
        width: '100%',
        defaultValue: '',
        required: false,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }, {
      type: 'input',
      icon: 'icon-input',
      name: '联系电话',
      model: 'mobile',
      special: 'device',
      key: Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999 + 7),
      rules: [],
      isSearch: false,
      isLikeSearch: true,
      isInTable: false,
      position: '',
      options: {
        width: '100%',
        defaultValue: '',
        required: false,
        dataType: 'string',
        pattern: '',
        placeholder: '请输入',
        disabled: false
      }
    }]
  }
]
