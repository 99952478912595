<template>
  <!-- 表单内容 -->
  <el-container class="fm2-container">
    <el-main class="fm2-main">
      <el-container>
        <el-aside width="250px">
          <div class="components-list">

            <template v-if="basicFields.length">
              <div class="widget-cate">{{$t('fm.components.basic.title')}}</div>
              <draggable tag="ul" :list="basicComponents"
                         v-bind="{group:{ name:'people', pull:'clone',put:false},sort:false, ghostClass: 'ghost'}"
                         @end="handleMoveEnd"
                         @start="handleMoveStart"
                         :move="handleMove"
              >
                <template v-for="(item, index) in basicComponents">
                  <li v-if="basicFields.indexOf(item.type)>=0" class="form-edit-widget-label" :class="{'no-put': item.type == 'divider'}" :key="index">
                    <a>
                      <i class="icon iconfont" :class="item.icon"></i>
                      <span>{{item.name}}</span>
                    </a>
                  </li>
                </template>
              </draggable>
            </template>

            <template v-if="advanceFields.length">
              <div class="widget-cate">{{$t('fm.components.advance.title')}}</div>
              <draggable tag="ul" :list="advanceComponents"
                         v-bind="{group:{ name:'people', pull:'clone',put:false},sort:false, ghostClass: 'ghost'}"
                         @end="handleMoveEnd"
                         @start="handleMoveStart"
                         :move="handleMove"
              >
                <template v-for="(item, index) in advanceComponents">
                  <li v-if="advanceFields.indexOf(item.type) >= 0 && isH5?item.type!='imgupload':item.type!='imgH5upload'" class="form-edit-widget-label" :class="{'no-put': item.type == 'table'}" :key="index">
                    <a>
                      <i class="icon iconfont" :class="item.icon"></i>
                      <span>{{item.name}}</span>
                    </a>
                  </li>
                </template>
              </draggable>
            </template>

            <template v-if="layoutFields.length">
              <div class="widget-cate">{{$t('fm.components.layout.title')}}</div>
              <draggable tag="ul" :list="layoutComponents"
                         v-bind="{group:{ name:'people', pull:'clone',put:false},sort:false, ghostClass: 'ghost'}"
                         @end="handleMoveEnd"
                         @start="handleMoveStart"
                         :move="handleMove"
              >
                <template v-for="(item, index) in layoutComponents">
                  <li v-if="layoutFields.indexOf(item.type) >=0" class="form-edit-widget-label no-put" :key="index">
                    <a>
                      <i class="icon iconfont" :class="item.icon"></i>
                      <span>{{item.name}}</span>
                    </a>
                  </li>
                </template>
              </draggable>
            </template>

            <div class="widget-cate">{{$t('fm.components.special.title')}}</div>
              <template v-if="!isH5">
                <draggable  tag="ul" :list="specialPCComponentsClone"
                            v-bind="{group:{ name:'people', pull:'clone',put:false},sort:false, ghostClass: 'ghost'}"
                            @end="handleMoveEnd"
                            @start="handleMoveStart"
                            :move="handleMove"
                            :filter="'.unmover'"
                >
                  <template v-for="(item, index) in specialPCComponentsClone">
                    <li class="form-edit-widget-label" :key="index" :class="{'unmover': specialList.indexOf(item.type) != -1}">
                      <a>
                        <i class="icon iconfont" :class="item.icon"></i>
                        <span>{{item.name}}</span>
                      </a>
                    </li>
                  </template>
                </draggable>
              </template>
              <template v-else>
                <draggable  tag="ul" :list="specialH5ComponentsClone"
                            v-bind="{group:{ name:'people', pull:'clone',put:false},sort:false, ghostClass: 'ghost'}"
                            @end="handleMoveEnd"
                            @start="handleMoveStart"
                            :move="handleMove"
                            :filter="'.unmover'"
                >
                  <template v-for="(item, index) in specialH5ComponentsClone">
                    <li class="form-edit-widget-label" :key="index" :class="{'unmover': specialList.indexOf(item.type) != -1}">
                      <a>
                        <i class="icon iconfont" :class="item.icon"></i>
                        <span>{{item.name}}</span>
                      </a>
                    </li>
                  </template>
                </draggable>
              </template>
            </div>

        </el-aside>
        <el-container class="center-container" direction="vertical">

          <el-main :class="{'widget-empty': widgetForm.list.length == 0}">

            <widget-form v-if="!resetJson"  ref="widgetForm" :data="widgetForm" :select.sync="widgetFormSelect" @getList="getList"></widget-form>
          </el-main>
        </el-container>

        <el-aside class="widget-config-container">
          <el-container>
            <el-header height="45px">
              <div class="config-tab" :class="{active: configTab=='widget'}" @click="handleConfigSelect('widget')">{{$t('fm.config.widget.title')}}</div>
              <div class="config-tab" :class="{active: configTab=='form'}" @click="handleConfigSelect('form')">{{$t('fm.config.form.title')}}</div>
            </el-header>
            <el-main class="config-content">
              <widget-config v-show="configTab=='widget'" :data="widgetFormSelect" :isH5="isH5" :otherWidget="otherWidget"></widget-config>
              <form-config v-show="configTab=='form'" :data="widgetForm.config"></form-config>
            </el-main>
          </el-container>

        </el-aside>

        <cus-dialog
          :visible="previewVisible"
          @on-close="previewVisible = false"
          ref="widgetPreview"
          :top="`${isH5?'3%':'8%'}`"
          :width="`${isH5?'25%':'55%'}`"
          form
        >
          <generate-form insite="true" @on-change="handleDataChange" v-if="previewVisible" :data="widgetForm" :value="widgetModels" :remote="remoteFuncs" ref="generateForm">

          </generate-form>
        </cus-dialog>

        <cus-dialog
          :visible="jsonVisible"
          @on-close="jsonVisible = false"
          ref="jsonPreview"
          width="800px"
          form
        >

          <div id="jsoneditor" style="height: 400px;width: 100%;">{{jsonTemplate}}</div>

          <template slot="action">
            <el-button type="primary" class="json-btn" :data-clipboard-text="jsonCopyValue">{{$t('fm.actions.copyData')}}</el-button>
          </template>
        </cus-dialog>

        <cus-dialog
          :visible="codeVisible"
          @on-close="codeVisible = false"
          ref="codePreview"
          width="800px"
          form
          :action="false"
        >
          <el-tabs type="border-card" style="box-shadow: none;" v-model="codeActiveName">
            <el-tab-pane label="Vue Component" name="vue">
              <div id="vuecodeeditor" style="height: 500px; width: 100%;">{{vueTemplate}}</div>
            </el-tab-pane>
            <el-tab-pane label="HTML" name="html">
              <div id="codeeditor" style="height: 500px; width: 100%;">{{htmlTemplate}}</div>
            </el-tab-pane>
          </el-tabs>
        </cus-dialog>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import Draggable from 'vuedraggable'
import WidgetConfig from './WidgetConfig'
import FormConfig from './FormConfig'
import WidgetForm from './WidgetForm'
import CusDialog from './CusDialog'
import GenerateForm from './GenerateForm'
import Clipboard from 'clipboard'
import {basicComponents, layoutComponents, advanceComponents, specialPCComponents, specialH5Components} from './componentsConfig.js'
import request from '../util/request.js'

export default {
  components: {
    Draggable,
    WidgetConfig,
    FormConfig,
    WidgetForm,
    CusDialog,
    GenerateForm
  },
  props: {
    isH5: {
      type: Boolean,
      default: false
    },
    basicFields: {
      type: Array,
      default: () => ['input', 'textarea', 'number', 'radio', 'checkbox', 'time', 'date', 'rate', 'select', 'switch', 'text']
    },
    advanceFields: {
      type: Array,
      default: () => ['imgupload', 'imgH5upload', 'editor', 'cascader']
    },
    layoutFields: {
      type: Array,
      default: () => ['grid']
    }
  },
  watch: {
    specialPCComponentsClone: {
      deep: true,
      handler (val) {
        // 监听特殊字段是否被改变，当改变时进行还原
        if (JSON.stringify(this.specialPCComponentsClone) != JSON.stringify(this.specialPCComponents)) {
          this.specialPCComponentsClone = JSON.parse(JSON.stringify(this.specialPCComponents))
        }
      }
    },
    specialH5ComponentsClone: {
      deep: true,
      handler (val) {
        // 监听特殊字段是否被改变，当改变时进行还原
        if (JSON.stringify(this.specialH5ComponentsClone) != JSON.stringify(this.specialH5Components)) {
          this.specialH5ComponentsClone = JSON.parse(JSON.stringify(this.specialH5Components))
        }
      }
    }
  },
  data () {
    return {
      otherWidget: [],
      basicComponents,
      layoutComponents,
      advanceComponents,
      specialPCComponents,
      specialPCComponentsClone: null,
      specialH5Components,
      specialH5ComponentsClone: null,
      resetJson: false,
      widgetForm: {
        list: [],
        config: {
          labelWidth: 100,
          labelPosition: 'right',
          size: 'small'
        },
      },
      configTab: 'widget',
      widgetFormSelect: null,
      previewVisible: false,
      jsonVisible: false,
      codeVisible: false,
      uploadVisible: false,
      remoteFuncs: {
        func_test (resolve) {
          setTimeout(() => {
            const options = [
              {id: '1', name: '1111'},
              {id: '2', name: '2222'},
              {id: '3', name: '3333'}
            ]
            resolve(options)
          }, 2000)
        },
        funcGetToken (resolve) {
          request.get('http://tools-server.xiaoyaoji.cn/api/uptoken').then(res => {
            resolve(res.uptoken)
          })
        },
        upload_callback (response, file, fileList) {
          console.log('callback', response, file, fileList)
        }
      },
      specialList: [],
      widgetModels: {},
      blank: '',
      htmlTemplate: '',
      vueTemplate: '',
      jsonTemplate: '',
      uploadEditor: null,
      jsonCopyValue: '',
      jsonClipboard: null,
      codeActiveName: 'vue',
    }
  },
  mounted () {
    this.specialPCComponentsClone = JSON.parse(JSON.stringify(this.specialPCComponents))
    this.specialH5ComponentsClone = JSON.parse(JSON.stringify(this.specialH5Components))
    this._loadComponents()
  },
  methods: {
    // 给字段新增名称属性
    _loadComponents () {
      this.basicComponents = this.basicComponents.map(item => {
        return {
          ...item,
          name: this.$t(`fm.components.fields.${item.type}`)
        }
      })
      this.advanceComponents = this.advanceComponents.map(item => {
        return {
          ...item,
          name: this.$t(`fm.components.fields.${item.type}`)
        }
      })
      this.layoutComponents = this.layoutComponents.map(item => {
        return {
          ...item,
          name: this.$t(`fm.components.fields.${item.type}`)
        }
      })
      // this.specialPCComponentsClone = this.specialPCComponentsClone.map(item => {
      //   return {
      //     ...item,
      //     name: this.$t(`fm.components.fields.${item.type}`)
      //   }
      // })
      // this.specialH5ComponentsClone = this.specialH5ComponentsClone.map(item => {
      //   return {
      //     ...item,
      //     name: this.$t(`fm.components.fields.${item.type}`)
      //   }
      // })
    },
    getList (list) {
      this.specialList = list.map(item => {
        return item.special
      })
    },
    handleConfigSelect (value) {
      this.configTab = value
    },
    handleMoveEnd (evt) {
      console.log('end', evt)
    },
    handleMoveStart ({oldIndex}) {
      console.log('start', oldIndex, this.basicComponents)
    },
    handleMove () {
      return true
    },

    handleTest () {
      this.$refs.generateForm.getData().then(data => {
        this.$alert(data, '').catch(e=>{})
        this.$refs.widgetPreview.end()
      }).catch(e => {
        this.$refs.widgetPreview.end()
      })
    },
    handleReset () {
      this.$refs.generateForm.reset()
    },
    clear () {
      this.handleClear()
    },
    setJSON (json) {
      this.widgetForm = json

      if (json.list.length > 0) {
        this.widgetFormSelect = json.list[0]
      }
    },
    handleInput (val) {
      console.log(val)
      this.blank = val
    },
    handleDataChange (field, value, data) {
      console.log(field, value, data)
    }
  }
}
</script>

<style scoped>

</style>
