<template>
  <!-- 表单页面 -->
  <div class="fm-style">
    <el-form ref="generateForm"
      label-suffix=":"
      :size="data.config.size"
      :model="models" :rules="rules" :disabled="readonly" :label-position="data.config.labelPosition" :label-width="data.config.labelWidth + 'px'">
      <template v-for="item in data.list">

        <template v-if="item.type == 'grid'">
          <el-row
            :key="item.key"
            type="flex"
            :gutter="item.options.gutter ? item.options.gutter : 0"
            :justify="item.options.justify"
            :align="item.options.align"
          >
            <el-col v-for="(col, colIndex) in item.columns" :key="colIndex" :span="col.span">


              <template v-for="citem in col.list" >
                <genetate-form-item
                  :key="citem.key"
                  :models.sync="models"
                  :remote="remote"
                  :rules="rules"
                  :widget="citem"
                  :readonly="readonly"
                  @input-change="onInputChange">
                </genetate-form-item>
              </template>
            </el-col>
          </el-row>
        </template>

        <template v-else>
          <genetate-form-item
            :key="item.key"
            :models.sync="models"
            :rules="rules"
            :widget="item"
            :readonly="readonly"
            @input-change="onInputChange"
            :remote="remote">
          </genetate-form-item>
        </template>

      </template>
    </el-form>
  </div>
</template>

<script>
import GenetateFormItem from './GenerateFormItem'
import {loadJs} from '../util/index.js'

export default {
  name: 'fm-generate-form',
  components: {
    GenetateFormItem
  },
  props: ['data', 'remote', 'value', 'insite', 'readonly'],
  data () {
    return {
      models: {},
      rules: {},
      userForm: {},
      deviceInfo: {}
    }
  },
  created () {
    this.userForm = {}
    this.deviceInfo = {}
    this.generateModle(this.data.list)
  },
  mounted () {
  },
  methods: {
    // 提取表单中所有的model和rule
    generateModle (genList) {
      for (let i = 0; i < genList.length; i++) {
        if (genList[i].special === 'res' && genList[i].type !== 'grid') {
          this.userForm[genList[i].model] = ''
        }
        if (genList[i].special === 'device' && genList[i].type !== 'grid') {
          this.deviceInfo[genList[i].model] = ''
        }
        if (JSON.stringify(this.userForm) != '{}' && genList[i].model === 'houseId') {
          this.userForm['houseId'] = ''
        }
        if (genList[i].type === 'grid') {
          genList[i].columns.forEach(item => {
            this.generateModle(item.list)
          })
        } else {
          if (this.value && Object.keys(this.value).indexOf(genList[i].model) >= 0) {
            this.models[genList[i].model] = this.value[genList[i].model]
          } else {
            if (genList[i].options.dataType == 'number' || genList[i].options.dataType == 'integer' || genList[i].options.dataType == 'float') {
              this.models[genList[i].model] = genList[i].options.defaultValue ? Number(genList[i].options.defaultValue) : ''
            } else {
              this.models[genList[i].model] = genList[i].options.defaultValue
            }
          }
          if (this.rules[genList[i].model]) {
            this.rules[genList[i].model] = genList[i].rules ? [...this.rules[genList[i].model], ...genList[i].rules.map(item => {
              if (item.pattern) {
                return {...item, pattern: eval(item.pattern)}
              } else {
                return {...item}
              }
            })] : null
          } else {
            this.rules[genList[i].model] = genList[i].rules ? [...genList[i].rules.map(item => {
              if (item.pattern) {
                return {...item, pattern: eval(item.pattern)}
              } else {
                return {...item}
              }
            })] : null
          }
        }
      }
    },
    // 获取表单中所有的model
    getData () {
      return new Promise((resolve, reject) => {
        this.$refs.generateForm.validate(valid => {
          if (valid) {
            if (JSON.stringify(this.userForm) != '{}') {
              for (var i in this.userForm) {
                this.userForm[i] = this.models[i]
              }
              this.models['userForm'] = this.userForm
            }
            if (JSON.stringify(this.deviceInfo) != '{}') {
              for (var i in this.deviceInfo) {
                this.deviceInfo[i] = this.models[i]
              }
              this.models['deviceInfo'] = this.deviceInfo
            }
            resolve(this.models)
          } else {
            reject(new Error(this.$t('fm.message.validError')).message)
          }
        })
      })
    },
    // 重置表单
    reset () {
      this.$refs.generateForm.resetFields()
      console.log(this.models)
    },
    // 表单数据改变时触发
    onInputChange (value, field) {
      this.$emit('on-change', field, value, this.models)
    },
    refresh () {
    }
  },
  watch: {
    data: {
      // deep: true,
      handler (val) {
        this.generateModle(val.list)
      }
    },
    value: {
      deep: true,
      handler (val) {
        this.models = {...this.models, ...val}
      }
    }
  }
}
</script>

<style lang="scss">
// @import '../styles/cover.scss';
</style>
