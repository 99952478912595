import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    noticeId: 1,
    guideId: 1,
    repairData: {},
    repairHandling: {},
    problemData: {},
    problemHandling:{},
    activityId: 1,
    activityType: 1,
    nationality: 0,
    patrolId: 1,
    nation: 0,
    visitId: 1,
    deviceId: 1,
    branchId:1,
    partyId:1,
    houseId:0,
    migrantNum: 0, // 流动人口
    visitTaskData: {},
    patrolData: {},
    activityData: {},
    otherData: {},
    visitData: {},
    problemVisitIds: '',
    placeInfo: {},
    resList: [],
    workerList: [],
    active: 1
  },
  mutations: {
    setVisitTask(state, data) {
      state.visitTaskData = data || {}
    },
    setPatrolTask(state, data) {
      state.patrolData = data || {}
    },
    setActivityTask(state, data) {
      state.activityData = data || {}
    },
    setOtherTask(state, data) {
      state.otherData = data || {}
    },
    setHouseId(state,id) {
      state.houseId = id || 0
    },
    setMigrantNum(state,num) {
      state.migrantNum = num || 0
    },
    setNoticeId(state,id) {
      state.noticeId = id || 1
    },
    setGuideId(state,id) {
      state.guideId = id || 1
    },
    setActivityId(state,id) {
      state.activityId = id || 1
    },
    setActivityType(state, id) {
      state.activityType = id || 1
    },
    // 问题跳转详情
    setProblemData(state,data) {
      state.problemData = data || {}
    },
    // 问题id
    setProblemIds(state,ids) {
      state.problemVisitIds += ids + ','
    },
    setProblemHandling(state,data) {
      state.problemHandling = data || {}
    },
    // 报修跳转详情
    setRepairData(state,data) {
      state.repairData = data || {}
    },
    // 走访跳转详情
    setVisitData(state,data) {
      state.visitData = data || {}
    },
    setRepairHandling(state,data) {
      state.repairHandling = data || {}
    },
    setDeviceId(state,id) {
      state.deviceId = id || 1
    },
    setNationality(state,id) {
      state.nationality = id || 0
    },
    setNation(state,id) {
      state.nation = id || 0
    },
    setPatrolId(state,id) {
      state.patrolId = id || 1
    },
    setVisitId(state,id) {
      state.visitId = id || 1
    },
    setBranch(state,id) {
      state.branchId = id || 1
    },
    setParty(state,id) {
      state.partyId = id || 1
    },
    setPlaceInfo(state, data) {
      state.placeInfo = data
    },
    setResList(state, data) {
      state.resList = data
    },
    setWorkerList(state, data) {
      state.workerList = data
    },
    /**
     * 当前活动tab
     * @param state
     * @param data
     */
    setActive(state, data) {
      state.active = data
    }
  },
  actions: {
  },
  modules: {
  }
})
