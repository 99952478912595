<template>
  <!-- 表单容器 -->
  <div class="fm-style">
    <el-header class="btn-bar" :class="{'btn-bar2': category==2}">
<!--      <el-button v-if="upload" type="text" size="medium" icon="el-icon-upload2" @click="handleUpload">{{$t('fm.actions.import')}}</el-button>-->
      <el-button v-if="true" type="text" size="medium" icon="el-icon-delete" @click="handleClear">{{$t('fm.actions.clear')}}</el-button>
      <el-button v-if="true" type="text" size="medium" icon="el-icon-view" @click="handlePreview">{{$t('fm.actions.preview')}}</el-button>
<!--      <el-button v-if="generateJson" type="text" size="medium" icon="el-icon-tickets" @click="handleGenerateJson">{{$t('fm.actions.json')}}</el-button>-->
<!--      <el-button v-if="generateCode" type="text" size="medium" icon="el-icon-document" @click="handleGenerateCode">{{$t('fm.actions.code')}}</el-button>-->
    </el-header>
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <el-tab-pane label="PC表单" name="first" v-if="category==1">
        <form-content ref="pcForm"></form-content>
      </el-tab-pane>

      <el-tab-pane label="H5表单" name="second">
        <form-content ref="h5Form" :isH5="true"></form-content>
      </el-tab-pane>
    </el-tabs>
    <!-- 插槽，表单提交按钮 -->
    <div class="btn-submit">
      <slot name="action"></slot>
    </div>

  </div>
</template>

<script>
import FormContent from './FormContent'
export default {
  name: 'fm-making-form',
  components: {
    FormContent
  },
  props: ['category'],
  data () {
    return {
      activeName: 'first'
    }
  },
  watch: {
    category: {
      immediate: true,
      handler (val) {
        if (val == 2) {
          this.activeName = 'second'
        }
      }
    }
  },
  methods: {
    tabClick () {
      if (this.activeName === 'first') {
        this.$refs.pcForm.otherWidget = this.$refs.h5Form.widgetForm.list
      } else if (this.activeName === 'second') {
        this.$refs.h5Form.otherWidget = this.$refs.pcForm.widgetForm.list
      }
    },
    // 清除表单内容
    handleClear () {
      if (this.activeName === 'first') {
        this.$refs.pcForm.widgetForm = {
          list: [],
          config: {
            labelWidth: 100,
            labelPosition: 'right',
            size: 'small',
            customClass: ''
          },
        }
        this.$refs.pcForm.widgetFormSelect = {}
      } else {
        this.$refs.h5Form.widgetForm = {
          list: [],
          config: {
            labelWidth: 100,
            labelPosition: 'right',
            size: 'small',
            customClass: ''
          },
        }
        this.$refs.h5Form.widgetFormSelect = {}
      }
    },
    // 预览表单
    handlePreview () {
      if (this.activeName === 'first') {
        this.$refs.pcForm.previewVisible = true
      } else {
        this.$refs.h5Form.previewVisible = true
      }
    },
    // 设置表单JSON
    setJSON (pcJson, h5Json) {
      let widgetForm = {
        list: [],
        config: {
          labelWidth: 100,
          labelPosition: 'right',
          size: 'small',
          customClass: ''
        }
      }
      if (this.category == 1) {
        this.$refs.pcForm.widgetForm = pcJson || JSON.parse(JSON.stringify(widgetForm))
        this.$refs.pcForm.widgetFormSelect = {}
      } 
      this.$refs.h5Form.widgetForm = h5Json || JSON.parse(JSON.stringify(widgetForm))
      this.$refs.h5Form.widgetFormSelect = {}
      if (pcJson && pcJson.list.length > 0) {
        this.$refs.pcForm.widgetFormSelect = pcJson.list[0]
      }
      if (h5Json && h5Json.list.length > 0) {
        this.$refs.h5Form.widgetFormSelect = h5Json.list[0]
      }
    },
    // 获取表单JSON
    getJSON () {
      return {
        pcJson: this.category == 1 ? this.$refs.pcForm.widgetForm : null,
        h5Json: this.$refs.h5Form.widgetForm
      }
    }
  }
}
</script>
