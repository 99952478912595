let watermark = {}

let setWatermark = (str1, str2) => {
  let id = '1.23452384164.123412416'

  if (document.getElementById(id) !== null) {
    document.getElementById('watermark').removeChild(document.getElementById(id))
  }

  // 创建一个画布
  let can = document.createElement('canvas')
  // 设置画布的长宽
  can.width = 200
  can.height = 160

  let cans = can.getContext('2d')
  // 旋转角度
  cans.rotate(-20 * Math.PI / 180)
  cans.font = '12px Vedana'
  // 设置填充绘画的颜色、渐变或者模式
  cans.fillStyle = 'rgba(200, 200, 200, 0.40)'
  // 设置文本内容的当前对齐方式
  cans.textAlign = 'center'
  // 设置在绘制文本时使用的当前文本基线
  cans.textBaseline = 'Middle'
  // 在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
  cans.fillText(str1, can.width / 4, can.height / 1.5)
  cans.fillText(str2, can.width / 4, can.height)

  let div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '0'
  div.style.left = '0'
  div.style.position = 'fixed'
  div.style.zIndex = '100'
  div.style.width = document.documentElement.scrollWidth + 'px'
  div.style.height = document.documentElement.scrollHeight + 'px'
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  if (document.getElementById('watermark') !== null) {
    document.getElementById('watermark').appendChild(div)
  }
  return id
}

// 该方法只允许调用一次
watermark.set = (str1, str2) => {
  let id = setWatermark(str1, str2)
  setInterval(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(str1, str2)
    }
  }, 500)
  window.onresize = () => {
    setWatermark(str1, str2)
  }
}
export default watermark
