import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import merge from 'lodash/merge'
import { clearLoginInfo } from '@/utils'
import rsaUtils from "@/utils/rsaUtils";

const http = axios.create({
  timeout: 1000 * 30,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8 ',
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  config.headers['token'] = config.headers['token'] || Vue.prototype.$globalData.token || sessionStorage.getItem('token')// 请求头带上token
  config.headers['topToken'] = Vue.prototype.$globalData.topToken||sessionStorage.getItem('topToken') // 请求头带上token
  // config.headers['token'] = Vue.prototype.$globalData.h5Token||"" // 请求头带上token
  config.headers['appid'] = Vue.prototype.$globalData.appid||sessionStorage.getItem('appid')||"wggg_zzd"
  config.headers['appletOrgId'] =Vue.prototype.$globalData.appletOrgId||sessionStorage.getItem('appletOrgId')
  if (Vue.prototype.$encrypt) {
    config.headers['encrypt'] = rsaUtils.encrypt(Vue.prototype.$encrypt)
  } else {
    config.headers['encrypt'] = rsaUtils.encrypt(localStorage.getItem('isDesensitizer') == 0 ? 'off' : 'on')
  }
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data && response.data.code === 401) { // 401, token失效
    clearLoginInfo()
    router.push({ name: 'login' })
  }
  return response
}, error => {
  return Promise.reject(error)
})

/**
 * 请求地址根路径处理
 * @param {*} actionName action方法名称
 */
http.changeUrl = (actionName, base= true) => {
  if (base) {
    Vue.$baseURL = actionName? actionName+ "/api": process.env.VUE_APP_BASE_URL
  } else {
    Vue.$baseURL = actionName? actionName: process.env.VUE_APP_BASE_URL
  }
}

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : process.env.VUE_APP_BASE_URL) + actionName
  let URL = process.env.VUE_APP_BASE_URL
  // alert(URL)
  // alert("Vue.$baseURL====="+Vue.$baseURL)
  // console.log("Vue.$baseURL====="+Vue.$baseURL)
  if(Vue.$baseURL) {URL = Vue.$baseURL}

  // alert((process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : URL) + actionName)
  return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : URL) + actionName
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    't': new Date().getTime()
  }
  const  enc = JSON.parse(JSON.stringify(params));
  // 加密分页参数，防止遍历漏洞
  if (enc.page) {
    enc.page = rsaUtils.encrypt(enc.page + '')
  }
  if (enc.limit) {
    enc.limit = rsaUtils.encrypt(enc.limit + '')
  }

  return openDefultParams ? merge(defaults, enc) : enc
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    't': new Date().getTime()
  }
  // 加密分页参数，防止遍历漏洞
  let enc = JSON.parse(JSON.stringify(data));
  // 加密分页参数，防止遍历漏洞
  if (enc.page) {
    enc.page = rsaUtils.encrypt(enc.page + '')
  }
  if (enc.limit) {
    enc.limit = rsaUtils.encrypt(enc.limit + '')
  }
  enc = openDefultdata ? merge(defaults, enc) : enc
  return contentType === 'json' ? JSON.stringify(enc) : qs.stringify(enc)
}

export default http
