import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import merge from 'lodash/merge'
import { clearLoginInfo } from '@/utils'
import rsaUtils from "@/utils/rsaUtils";

const http = axios.create({
  timeout: 1000 * 30,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  // config.headers['token'] = sessionStorage.getItem('appToken') // 请求头带上appToken
  config.headers['token'] = Vue.prototype.$globalData.token || sessionStorage.getItem('token')// 请求头带上appToken
  config.headers['appid'] = Vue.prototype.$globalData.appid||sessionStorage.getItem('appid')||"wggg_zzd"
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data && response.data.code === 401) { // 401, token失效
    clearLoginInfo()
    router.push({ name: 'login' })
  }
  return response
}, error => {
  return Promise.reject(error)
})
http.changeUrl = (actionName, base= true) => {
  if (base) {
    if (actionName.indexOf("https://sgg.hzxh.gov.cn") > -1){
      Vue.$baseAppURL = actionName? actionName+ "/police-api": process.env.VUE_APP_BASE_CUSTAPP_URL
    }else {
      Vue.$baseAppURL = actionName? actionName+ "/cust-api": process.env.VUE_APP_BASE_CUSTAPP_URL
    }
  } else {
    Vue.$baseAppURL = actionName? actionName + "/": process.env.VUE_APP_BASE_CUSTAPP_URL
  }
}
/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  let URL = Vue.$baseAppURL || process.env.VUE_APP_BASE_CUSTAPP_URL
  return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : URL) + actionName
}
/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    't': new Date().getTime()
  }
  const  enc = JSON.parse(JSON.stringify(params));
  // 加密分页参数，防止遍历漏洞
  // if (enc.page) {
  //   enc.page = rsaUtils.encrypt(enc.page + '')
  // }
  // if (enc.limit) {
  //   enc.limit = rsaUtils.encrypt(enc.limit + '')
  // }

  return openDefultParams ? merge(defaults, enc) : enc
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    't': new Date().getTime()
  }
  // 加密分页参数，防止遍历漏洞
  let enc = JSON.parse(JSON.stringify(data));
  // 加密分页参数，防止遍历漏洞
  if (enc.page) {
    enc.page = rsaUtils.encrypt(enc.page + '')
  }
  if (enc.limit) {
    enc.limit = rsaUtils.encrypt(enc.limit + '')
  }
  enc = openDefultdata ? merge(defaults, enc) : enc
  return contentType === 'json' ? JSON.stringify(enc) : qs.stringify(enc)
}

export default http
